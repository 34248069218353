import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import MessageHistory from "./MessageHistory";
import CallHistory from "./CallHistory";
import CustomerProfileHeaderNav from "../../smallComponent/CustomerProfileHeaderNav";

const CallAndMessageDetails = () => {
  return (
    <Fragment>
      <div className="mb-[24px]">
        <CustomerProfileHeaderNav />
      </div>

      {/* Gallery and Contact */}
      <div className="mt-[30px] flex flex-col items-center justify-between gap-5 md:items-start lg:flex-row ">
        <MessageHistory></MessageHistory>
        <CallHistory></CallHistory>
      </div>
    </Fragment>
  );
};

export default CallAndMessageDetails;
