import React, { Fragment, useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Gallery from "./Gallery";
import Contact from "./Contact";
import CustomerProfileHeaderNav from "../../smallComponent/CustomerProfileHeaderNav";
import { AuthContext } from "../../../Provider/AuthProvider";
import { ENDPOINT } from "../../../App/config/endpoint";
import axios from "axios";

const MediaContactDetails = () => {
  const { token } = useContext(AuthContext);
 
  const [galleryData, setGalleryData] = useState([]);
  const [contactData, setContactData] = useState([]);
  const location = useLocation();
  const currentLocation = location.pathname;
  const segments = currentLocation.split("/")[3];
  const idNumber = decodeURIComponent(segments);

  const getGalleryData = () => {
    const apiUrl = `${process.env.REACT_APP_MAIN_URL}${ENDPOINT.info.galleryData}`;

    const postData = {
      user_id: idNumber,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    axios
      .post(apiUrl, postData, { headers })
      .then((res) => {
        console.log(res);
        // const parsedData = JSON.parse(res);
        // const parsedData = JSON.parse(res?.data?.data);
        setGalleryData(res);
      })
      .catch((err) => {
        console.log("media err: ", err);
      });
  };

  const getContactData = () => {
    const apiUrl = `${process.env.REACT_APP_MAIN_URL}${ENDPOINT.info.contactData}`;

    const postData = {
      user_id: idNumber,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    axios
      .post(apiUrl, postData, { headers })
      .then((res) => {
        console.log(res)
        // Parse the stringified JSON data into an actual object/array
        const parsedData = JSON.parse(res?.data?.data?.data);
        setContactData(parsedData);

        // For debugging purposes, you can log the parsed data
        // console.log("Parsed Contact Data:", parsedData);
      })
      .catch((err) => {
        console.log("contact err: ", err);
      });
  };

  useEffect(() => {
    getGalleryData();
    getContactData();
  }, []);
  return (
    <Fragment>
      {/* Gallery and Contact */}
      <div className="mt-[30px] flex gap-5 flex-col xl:flex-row items-center md:items-start justify-between ">
        <Gallery gallery={galleryData}></Gallery>
        <Contact contact={contactData}></Contact>
      </div>
      
    </Fragment>
  );
};

export default MediaContactDetails;
