import React from "react";
import NavBar from "../../Component/Shared/NavBar";
import UserProfileNav from "../../Component/CRM/SharedComponent/UserProfileNav";
import CallAndMessageDetails from "../../Component/CRM/CallAndMessage/CallAndMessageDetails";
const CallAndMessage = () => {
  return (
    <div className="min-h-screen bg-[#F0F2F5]">
      <NavBar></NavBar>
      <div className="mx-auto mt-[24px] max-w-[1300px]">
        <UserProfileNav></UserProfileNav>
        <div className="bg-white px-[50px] py-[25px]">
          <CallAndMessageDetails />
        </div>
      </div>
    </div>
  );
};

export default CallAndMessage;
