import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BsCurrencyRupee } from "react-icons/bs";
import TablePagination from "../../Shared/TablePagination";
import SmallModal from "../../Modals/SmallModal";
import { BlackSearchIcon } from "../../../Assets/icons";
import SharedModal from "../../Modals/SharedModal";
import Rollover from "../../ModalComponent/Rollover";
import CloseOrder from "../../ModalComponent/CloseOrder";
import { ENDPOINT } from "../../../App/config/endpoint";

const MyTaskTable = ({ tableData, tableHead, operations = true }) => {
  // console.log("data is", tableData);
  const [tableDatas2, setTableDatas2] = useState(tableData);
  const [searchState, setSearchState] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [smallModalOpen, setSmallModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalAction, setModalAction] = useState(false);
  const [searchValue, setSearchValue] = useState(false);
  const [searchMethod, setSearchMethod] = useState("");

  // Search Bar Code
  useEffect(() => {
    const filter = () => {
      if (searchState !== "") {
        const filteredArray = tableData.filter((dat) => {
          // Adjust the property names based on the searchMethod prop
          return dat[searchMethod]
            ?.toString()
            .toLowerCase()
            .includes(searchState.toLowerCase());
        });
        setTableDatas2(filteredArray);
      } else {
        setTableDatas2(tableData);
      }
    };

    filter();
  }, [searchState, searchMethod, tableData]);

  const setFilterOption = (e) => {
    setSearchMethod();
  };

  // pagination state
  const [data, setData] = useState(tableDatas2);
  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  // Pagination Item Set
  useEffect(() => {
    setData(tableDatas2);
    setcurrentPage(1);
  }, [tableDatas2]);

  // Rollover Modal
  const handleRolloverModal = (tbody) => {
    // console.log(tbody);
    setModalOpen(true);
    setModalTitle(`Rollover (Order ID: ${tbody?.order_Id})`);
    setModalContent(
      <Rollover
        setModalOpen={setModalOpen}
        data={tbody}
        endpoint={`${process.env.REACT_APP_MAIN_URL}${ENDPOINT.payment.rollover}`}
      />
    );
  };

  // Close Modal
  const handleClose = (tbody) => {
    setModalTitle(`Close (Order ID: ${tbody?.order_Id})`);
    setSmallModalOpen(true);
    setModalContent(
      <CloseOrder setModalOpen={setSmallModalOpen} data={tbody} />
    );
  };

  const handleSearch = (e, index) => {
    e.preventDefault();
    if (index === 1) {
      setSearchMethod("order_no");
      setSearchState(e.target.value);
    } else if (index === 2) {
      setSearchMethod("phone");
      setSearchState(e.target.value);
    } else if (index === 3) {
      setSearchMethod("full_name");
      setSearchState(e.target.value);
    } else if (index === 4) {
      setSearchMethod("followup_result");
      setSearchState(e.target.value);
    } else if (index === 5) {
      setSearchMethod("collection_stage");
      setSearchState(e.target.value);
    }
  };
  return (
    <div className="relative mx-auto pb-[60px]">
      <div className="grid grid-cols-1 gap-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        <div className="">
          <label
            htmlFor="collectionStage"
            className="font-hahmlet text-[14px] font-semibold leading-[22px] tracking-[0.2px] text-black"
          >
            Order ID
          </label>
          <div className="">
            <input
              type="text"
              className="relative w-full rounded-lg border bg-white px-3 py-2 font-poppins text-black focus:outline-none"
              placeholder="Search order id..."
              onChange={(e) => handleSearch(e, 1)}
              required={true}
            />
          </div>
        </div>

        <div className="">
          <label
            htmlFor="collectionStage"
            className="font-hahmlet text-[14px] font-semibold leading-[22px] tracking-[0.2px] text-black"
          >
            Cell Phone Number
          </label>
          <div className="">
            <input
              type="text"
              className="relative w-full rounded-lg border bg-white px-3 py-2 font-poppins text-black focus:outline-none "
              placeholder="Search phone..."
              onChange={(e) => handleSearch(e, 2)}
              required={true}
            />
          </div>
        </div>

        <div className="">
          <label
            htmlFor="collectionStage"
            className="font-hahmlet text-[14px] font-semibold leading-[22px] tracking-[0.2px] text-black"
          >
            Applicant
          </label>
          <div className="">
            <input
              type="text"
              className="relative w-full rounded-lg border bg-white px-3 py-2 font-poppins text-black focus:outline-none"
              placeholder="Search applicant name..."
              onChange={(e) => handleSearch(e, 3)}
              required={true}
            />
          </div>
        </div>

        <div className="">
          <label
            htmlFor="collectionStage"
            className="font-hahmlet text-[14px] font-semibold leading-[22px] tracking-[0.2px] text-black"
          >
            Last Follow Up Result
          </label>
          <div className="">
            <input
              type="text"
              className="relative w-full rounded-lg border bg-white px-3 py-2 font-poppins text-black focus:outline-none"
              placeholder="Last Follow Up..."
              onChange={(e) => handleSearch(e, 4)}
              required={true}
            />
          </div>
        </div>

        <div className="">
          <label
            htmlFor="collectionStage"
            className="font-hahmlet text-[14px] font-semibold leading-[22px] tracking-[0.2px] text-black"
          >
            Collection Stage
          </label>
          <div className="mt-[5px] flex h-[115px] w-full flex-col items-center gap-2 xs:mb-0 xs:h-[38px] xs:flex-row xs:gap-0">
            <input
              type="text"
              className="relative w-full rounded-lg border bg-white px-4 py-2 pl-[30px] font-poppins text-black focus:outline-none xs:rounded-r-none"
              placeholder="Collection stage..."
              onChange={(e) => handleSearch(e, 5)}
              required={true}
            />
            <button className="absolute left-[1px] rounded-l-lg border-y bg-transparent bg-white py-[10px] pl-2 text-base font-semibold text-black">
              <BlackSearchIcon />
            </button>
            <Link
              onClick={() => setSearchState("")}
              className="w-full rounded-lg border px-3 py-[9px] pt-[10px] text-center text-[14px] font-semibold text-[gray] xs:w-[65px] xs:rounded-l-none xs:text-left sm:py-[8px] sm:text-base"
            >
              Clear
            </Link>
          </div>
        </div>
      </div>
      <div className="mt-[30px] overflow-x-scroll rounded-lg bg-white pb-3 shadow-tableShadow">
        <table className="w-full">
          <thead className="w-full bg-[#F1F3F7]">
            <tr className="h-16 w-full">
              {tableHead?.map((thead) => {
                return (
                  <th
                    style={{ minWidth: thead.width }}
                    // width={thead.width}
                    key={thead.id}
                    className={`p-16px px-2 text-left font-hahmlet text-base font-medium text-black`}
                  >
                    {thead.name}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="relative w-full gap-3">
            {currentItems?.map((tbody, i) => {
              // console.log("tbody", tbody);
              return (
                <tr
                  key={i}
                  className="h-28 bg-white text-sm leading-none text-gray-800 hover:bg-gray-100"
                >
                  {Object.entries(tbody).map(([key, item], index) => {
                    // Check if the key is "id"
                    if (key === "id" || key === "user_id") {
                      return null; // Skip rendering
                    }

                    return (
                      <td className="pl-2" key={index}>
                        <div className="text-left font-poppins text-base font-normal">
                          <p>
                            {item || (
                              <span className="text-[15px] text-gray-400">
                                N/A
                              </span>
                            )}
                          </p>
                        </div>
                      </td>
                    );
                  })}

                  {operations && (
                    <td className="flex flex-col gap-[5px] py-1 pr-2">
                      <Link
                        to={`/user-profile/${encodeURIComponent(tbody?.id)}`}
                        className="rounded-[3px] bg-[#43BA79] px-2 py-[4px] text-center font-poppins text-[16px] text-white"
                      >
                        View Profile
                      </Link>
                      <button
                        onClick={() => handleRolloverModal(tbody)}
                        className="rounded-[3px] bg-[#398CC5] px-2 py-[4px] text-center font-poppins text-[16px] text-white"
                      >
                        Rollover
                      </button>
                      {/* <button
                        onClick={() => handleClose(tbody)}
                        className="rounded-[3px] bg-[#F75F00] px-2 py-[4px] text-center font-poppins text-[16px] text-white"
                      >
                        Close
                      </button> */}
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <TablePagination
        data={data}
        currentPage={currentPage}
        setcurrentPage={setcurrentPage}
        itemsPerPage={itemsPerPage}
      />
      <SharedModal
        title={modalTitle}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        modalContent={modalContent}
      />
      <SmallModal
        title={modalTitle}
        modalOpen={smallModalOpen}
        setModalOpen={setSmallModalOpen}
        modalContent={modalContent}
      />
    </div>
  );
};

export default MyTaskTable;
