import React, { lazy, Suspense, useState } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import GlobalLoader from "./GlobalLoader";
import PageNotFound from "./Pages/PageNotFound";
import UserProfile from "./Pages/CRM/UserProfile";
import MyTask from "./Pages/CRM/MyTask";
import MediaContact from "./Pages/CRM/MediaContact";
import CallAndMessage from "./Pages/CRM/CallAndMessage";
import Login from "./Pages/Auth/Login";
import RecoverAccount from "./Pages/Auth/RecoverAccount";
import CreateNewPass from "./Pages/Auth/CreateNewPass";
import Home from "./Pages/CRM/Home";
import AuthProvider from "./Provider/AuthProvider";
import PrivateRoute from "./PrivateRoute/PrivateRoute";
import { Toaster } from "react-hot-toast";
import OnBoarding from "./Pages/Auth/OnBoarding";
export default function App() {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <AuthProvider>
      <Toaster />
      <Suspense fallback={<GlobalLoader />}>
        <BrowserRouter>
          <Routes>
            <Route path="/loading" element={<GlobalLoader />} />
            <Route
              path="/crm-login"
              element={
                <Login isLoading={isLoading} setIsLoading={setIsLoading} />
              }
            />
            <Route
              path="/crm-recover-account"
              element={
                <RecoverAccount
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              }
            />
            <Route
              path="/crm-verify-account"
              element={
                <OnBoarding
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              }
            />
            <Route
              path="/crm-create-new-password"
              element={
                <CreateNewPass
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              }
            />

            <Route path="/" element={<PrivateRoute />}>
              <Route
                path="/"
                element={
                  <Home
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                  ></Home>
                }
              />
              <Route
                path="/my-task"
                element={
                  <MyTask
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                  ></MyTask>
                }
              />
              <Route
                path="/user-profile/:id"
                element={
                  <UserProfile
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                  />
                }
              />
              <Route
                path="/user-profile/profile/:id/media-contact-data"
                element={
                  <MediaContact>
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                  </MediaContact>
                }
              />
              <Route
                path="/user-profile/profile/:id/call-message-history"
                element={
                  <CallAndMessage>
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                  </CallAndMessage>
                }
              />
            </Route>

            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </BrowserRouter>
      </Suspense>
    </AuthProvider>
  );
}
