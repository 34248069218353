import React, { useState, Fragment, useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import { XIconClose } from "../../../Assets/userProfile/userProfile";
import { Listbox, Transition } from "@headlessui/react";
import { BsChevronDown } from "react-icons/bs";
import SharedModal from "../../Modals/SharedModal";
import AddReminder from "../../ModalComponent/AddReminder";
import Rollover from "../../ModalComponent/Rollover";
import { ENDPOINT } from "../../../App/config/endpoint";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { FiCopy } from "react-icons/fi"; // Import the copy icon
const UserProfileNav = ({ loanDetails }) => {
  // console.log(loanDetails);
  const [active, setActive] = useState(3);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalAction, setModalAction] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  // Memoize token to avoid unnecessary re-renders
  const token = useMemo(
    () => JSON.parse(localStorage.getItem("agentToken")),
    []
  );
  console.log("token", token);

  const fetchData = async () => {
    if (!token) {
      toast.error("No token found");
      return;
    }

    setIsLoading(true);
    const apiUrl = `${process.env.REACT_APP_MAIN_URL}${ENDPOINT?.appApi?.AddLink}`;

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token.token}`,
    };

    try {
      const response = await axios.post(apiUrl, {}, { headers });
      console.log("API Response:", response);

      if (
        response?.data?.status === true &&
        Array.isArray(response?.data?.message)
      ) {
        setData(response?.data?.message);
      } else {
        toast.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
      toast.error("Failed to fetch data");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [token]);

  const handleCopy = (link) => {
    navigator.clipboard
      .writeText(link)
      .then(() => {
        toast.success("Link copied to clipboard");
      })
      .catch((error) => {
        toast.error("Failed to copy link");
        console.error("Copy error: ", error);
      });
  };

  // For Active Button Functionality
  const handleClick = (buttonIndex) => {
    setActive(buttonIndex);
    if (buttonIndex === 0) {
      setModalOpen(true);
      setModalTitle("Add Reminder");
      setModalContent(
        <AddReminder
          loanId={loanDetails?.id}
          setModalOpen={setModalOpen}
          modalTitle={modalTitle}
        />
      );
    } else if (buttonIndex === 2) {
      setModalOpen(true);
      setModalTitle(`Repayment (Order ID: ${loanDetails?.order_no})`);
      setModalContent(
        <Rollover
          setModalOpen={setModalOpen}
          data={loanDetails}
          endpoint={`${process.env.REACT_APP_MAIN_URL}${ENDPOINT.payment.repayment}`}
        />
      );
    }
  };

  return (
    <div>
      <Toaster />
      <div className="mx-auto mb-6 flex min-h-[109px] max-w-[1300px] flex-col items-center justify-between bg-white px-[50px] py-[25px] lg:flex-row">
        {/* Order Id */}
        <div>
          <h1 className="font-hahmlet text-[25px] font-semibold leading-[44.8px] text-black md:text-[28px] lg:text-[32px]">
            Order ID: {loanDetails?.order_no}
          </h1>
        </div>

        {/* Right side buttons */}
        <ul className="mt-5 grid w-full grid-cols-2 gap-[24px] text-center md:w-fit md:grid-cols-4">
          <li
            onClick={() => handleClick(0)}
            className={`cursor-pointer rounded-[4px] p-[10px] ${
              active === 0 ? "userProfileActiveBtn" : "userProfileInActiveBtn"
            }`}
          >
            <button className="font-hahmlet text-[14px] font-semibold leading-[22px]">
              Add a reminder
            </button>
          </li>
          <li
            onClick={() => handleClick(1)}
            className={`cursor-pointer rounded-[4px] p-[10px] ${
              active === 1 ? "userProfileActiveBtn" : "userProfileInActiveBtn"
            }`}
          >
            {data?.length > 0 ? (
              <ul className="space-y-4">
                {data?.map((link) => (
                  <li
                    key={link.id}
                    className="flex items-center justify-between  pb-2"
                    onClick={() => handleCopy(link?.applink)}
                  >
                    <span>App Link</span>
                    <button className="text-gray-600 hover:text-black">
                      <FiCopy className="h-5 w-5" />
                    </button>
                  </li>
                ))}
              </ul>
            ) : (
              <p>No links available</p>
            )}
          </li>

          <li
            onClick={() => handleClick(2)}
            className={`cursor-pointer rounded-[4px] p-[10px] ${
              active === 2 ? "userProfileActiveBtn" : "userProfileInActiveBtn"
            }`}
          >
            <button className="font-hahmlet text-[14px] font-semibold leading-[22px]">
              Repayment
            </button>
          </li>

          <li
            onClick={() => handleClick(3)}
            className={`cursor-pointer rounded-[4px] p-[10px] ${
              active === 3 ? "userProfileActiveBtn" : "userProfileInActiveBtn"
            }`}
          >
            <Link
              to={`https://api.whatsapp.com/send?phone=${loanDetails?.user_details?.mobile_code.replace(
                "+",
                ""
              )}${loanDetails?.user_details?.mobile_no}`}
              target="_blank"
            >
              Whatsapp
            </Link>
          </li>
        </ul>
      </div>
      <SharedModal
        title={modalTitle}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        modalContent={modalContent}
        modalAction={modalAction}
      />
    </div>
  );
};

export default UserProfileNav;
