import React from "react";
import NavBar from "../../Component/Shared/NavBar";

const Home = () => {
  return (
    <div className="min-h-screen bg-[#F0F2F5] pb-[22px]">
      <NavBar />
      <div className="mx-auto mt-[24px] max-w-[1300px]">
        <div className="rounded-lg bg-white px-[20px] py-[30px] min-h-[500px] flex items-center justify-center">
          <h1 className="text-[35px] text-[#F75F00] text-center font-poppins font-medium">Welcome Collection System</h1>
      </div>
    </div>
    </div>
  );
};

export default Home;
