import React from "react";
import toast from "react-hot-toast";
import { FiCopy } from "react-icons/fi";

const ContactInformation = ({ profileData }) => {
  const handleCopy = (link) => {
    navigator.clipboard
      .writeText(link)
      .then(() => {
        toast.success("Link copied to clipboard");
      })
      .catch((error) => {
        toast.error("Failed to copy link");
        console.error("Copy error: ", error);
      });
  };
  return (
    <div className="">
      {/* Top Nav */}
      <div className="">
        <h1 className="py-8 pb-[10px] font-inter text-[23px] font-semibold leading-[44.8px] text-secondBlack  md:pb-[20px] md:text-[26px] lg:py-8">
          Contact Information
        </h1>
      </div>

      {/* Loan Information */}
      <div className="grid grid-cols-1 gap-[20px] pb-[10px] md:grid-cols-2 md:pb-[20px] lg:grid-cols-3 lg:gap-6 lg:pb-8">
        <div className="row-span-full">
          <h3 className="font-inter text-[18px] font-medium leading-[29.05px] text-secondBlack md:text-[21px]">
            First Person Name
          </h3>
          <div className="mt-[8px] flex min-h-[50px]  items-center rounded-lg border px-[16px] font-inter text-[18px] capitalize leading-[29.05px]   text-secondBlack shadow-smallShadow md:text-[21px]">
            {profileData?.first_person_name || (
              <span className="text-[18px] text-gray-400">N/A</span>
            )}
          </div>
        </div>
        <div>
          <h3 className="font-inter text-[18px] font-medium  leading-[29.05px] text-secondBlack md:text-[21px]">
            Contact Number
          </h3>
          <div
            onClick={() => handleCopy(profileData?.first_phone)}
            className="mt-[8px] flex min-h-[50px]  cursor-pointer items-center justify-between rounded-lg border px-[16px] font-inter text-[18px] capitalize leading-[29.05px]   text-secondBlack shadow-smallShadow md:text-[21px]"
          >
            {profileData?.first_phone || (
              <span className="text-[18px] text-gray-400">N/A</span>
            )}
            <FiCopy className="h-5 w-5" />
          </div>
        </div>
        <div>
          <h3 className="font-inter text-[18px] font-medium  leading-[29.05px] text-secondBlack md:text-[21px]">
            Relationship
          </h3>
          <div className="mt-[8px] flex min-h-[50px]  items-center rounded-lg border px-[16px] font-inter text-[18px] capitalize leading-[29.05px]   text-secondBlack shadow-smallShadow md:text-[21px]">
            {profileData?.first_relation || (
              <span className="text-[18px] text-gray-400">N/A</span>
            )}
          </div>
        </div>
        <div>
          <h3 className="font-inter text-[18px] font-medium leading-[29.05px]  text-secondBlack md:text-[21px]">
            Second Person Name
          </h3>
          <div className="mt-[8px] flex min-h-[50px]  items-center rounded-lg border px-[16px] font-inter text-[18px] capitalize leading-[29.05px]   text-secondBlack shadow-smallShadow md:text-[21px]">
            {profileData?.second_person_name || (
              <span className="text-[18px] text-gray-400">N/A</span>
            )}
          </div>
        </div>
        <div>
          <h3 className="font-inter text-[18px] font-medium leading-[29.05px]  text-secondBlack md:text-[21px]">
            Contact Number
          </h3>
          <div
            onClick={() => handleCopy(profileData?.second_phone)}
            className="mt-[8px] flex  min-h-[50px] items-center justify-between rounded-lg border px-[16px] font-inter text-[18px] leading-[29.05px]   text-secondBlack shadow-smallShadow md:text-[21px]"
          >
            {profileData?.second_phone || (
              <span className="text-[18px] text-gray-400">N/A</span>
            )}
            <FiCopy className="h-5 w-5" />
          </div>
        </div>
        <div>
          <h3 className="font-inter text-[18px] font-medium leading-[29.05px]  text-secondBlack md:text-[21px]">
            Relationship
          </h3>
          <div className="mt-[8px] flex min-h-[50px]  items-center rounded-lg border px-[16px] font-inter text-[18px] capitalize leading-[29.05px]   text-secondBlack shadow-smallShadow md:text-[21px]">
            {profileData?.second_relation || (
              <span className="text-[18px] text-gray-400">N/A</span>
            )}
          </div>
        </div>
        <div>
          <h3 className="font-inter text-[18px] font-medium  leading-[29.05px] text-secondBlack md:text-[21px]">
            Third Person Name
          </h3>
          <div className="mt-[8px] flex min-h-[50px]  items-center rounded-lg border px-[16px] font-inter text-[18px] capitalize leading-[29.05px]   text-secondBlack shadow-smallShadow md:text-[21px]">
            {profileData?.third_person_name || (
              <span className="text-[18px] text-gray-400">N/A</span>
            )}
          </div>
        </div>
        <div>
          <h3 className="font-inter text-[18px] font-medium  leading-[29.05px] text-secondBlack md:text-[21px]">
            Contact Number
          </h3>
          <div
            onClick={() => handleCopy(profileData?.third_phone)}
            className="mt-[8px] flex min-h-[50px]  items-center justify-between rounded-lg border px-[16px] font-inter text-[18px] capitalize leading-[29.05px]   text-secondBlack shadow-smallShadow md:text-[21px]"
          >
            {profileData?.third_phone || (
              <span className="text-[18px] text-gray-400">N/A</span>
            )}
            <FiCopy className="h-5 w-5" />
          </div>
        </div>
        <div>
          <h3 className="font-inter text-[18px] font-medium  leading-[29.05px] text-secondBlack md:text-[21px]">
            Relationship
          </h3>
          <div className="mt-[8px] flex min-h-[50px]  items-center rounded-lg border px-[16px] font-inter text-[18px] capitalize leading-[29.05px]   text-secondBlack shadow-smallShadow md:text-[21px]">
            {profileData?.third_relation || (
              <span className="text-[18px] text-gray-400">N/A</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactInformation;
