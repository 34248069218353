import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import "../../Styles/CRM/NavBar.css";
import { CollectionSystemIcon } from "../../Assets/userProfile/userProfile";
import { Menu } from "@headlessui/react";
import { BiLogOutCircle } from "react-icons/bi";
import { FaAngleDown } from "react-icons/fa6";
import { AuthContext } from "../../Provider/AuthProvider";

const NavBar = () => {
  const [selectLangOption, setSelectLangOption] = useState("English");

  const handleLogout = () => {
    localStorage.removeItem("agentToken");
    window.location.href = "/crm-login";
  };

  return (
    <nav className="bg-black py-[26px]">
      <div className="mx-auto max-w-[1300px]">
        <div className="relative flex items-center justify-between">
          <div className="absolute inset-y-0 right-0 flex items-center pr-5 md:hidden">
            {/* Mobile menu button*/}
            <button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none">
              <span className="absolute -inset-0.5" />
              <span className="sr-only">Open main menu</span>
            </button>
          </div>
          <div className="flex items-center justify-center pl-5 sm:justify-start">
            <Link
              to="/"
              className="flex flex-shrink-0 cursor-pointer items-center"
            >
              <div>
                <CollectionSystemIcon></CollectionSystemIcon>
              </div>
              <h3 className="ml-2 font-hahmlet text-[20px] font-medium leading-[34.75px] text-white lg:text-[24px]">
                Collection System
              </h3>
            </Link>
            <div className="hidden sm:ml-6 sm:block">
              <div className="flex-start flex space-x-4">
                <Link
                  to="/"
                  className="font-hahmlet text-[18px] font-medium text-[#F75F00]"
                >
                  Home
                </Link>
                <Link
                  to="/my-task"
                  className="font-hahmlet text-[18px] font-medium text-[#F75F00]"
                >
                  My Task
                </Link>
              </div>
            </div>
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            {/* Profile dropdown */}
            <select
              value={selectLangOption}
              onChange={(e) => setSelectLangOption(e.target.value)}
              className="focus-none select select-info w-full max-w-xs rounded-[4px] border-none bg-transparent font-poppins text-base font-normal text-white outline-none"
            >
              <option value="English" className="text-secondBlack">
                English
              </option>
              <option value="Hindi" className="text-secondBlack">
                Hindi
              </option>
              <option value="Arabic" className="text-secondBlack">
                Arabic
              </option>
            </select>

            <Menu>
              <Menu.Button className="flex items-center gap-1">
                <span className="ml-3 text-left font-poppins text-base font-normal text-white">
                  CRM
                </span>
                <FaAngleDown size={14} className="text-white" />
              </Menu.Button>
              <Menu.Items className="absolute right-[0] top-[40px] z-[10] flex min-w-[170px] flex-col ">
                <Menu.Item
                  as="button" // Use as="button" to fix the error
                  className="flex cursor-pointer items-center rounded-lg bg-white px-2 py-3 shadow-md hover:bg-[#e9e9e9]"
                  onClick={() => handleLogout()}
                >
                  <BiLogOutCircle size={18} className="text-secondBlack" />
                  <span className="pl-[5px] font-inter text-[14px] font-medium text-secondBlack">
                    Sign out
                  </span>
                </Menu.Item>
              </Menu.Items>
            </Menu>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
