import React, { Fragment, useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import "../../../Styles/CRM/UserProfile.css";
import {
  BlockIcon,
  CallIcon,
  GalleryIcon,
  BookMarkIcon,
  PageIcon,
  StatusIcon,
  TotalLoanIcon,
  TotalLoanPaidIcon,
  TotalLoanRecivedIcon,
} from "../../../Assets/userProfile/userProfile";
// import { AuthContext } from "../../../Providers/AuthProvider";
import SmallModal from "../../Modals/SmallModal";
import BlockCompo from "../../Shared/BlockCompo";
const CustomerProfileHeader = ({ loanDetails, profileData, id = "" }) => {
  console.log("profile Data", profileData);
  // const [profileData, setProfileData] = useState(null);
  const location = useLocation();
  const currentLocation = location.pathname;
  const currentPath = currentLocation.split("/")[1];
  const [smallModalOpen, setSmallModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const userId = loanDetails?.user_id;

  const segments = currentLocation.split("/");
  const idNumber = decodeURIComponent(segments[segments.length - 1]);
  // console.log(idNumber);
  console.log(profileData?.user_id);
  return (
    <Fragment>
      {/* User Profile Header: */}
      <div>
        {/* Customer Profile Options */}
        <div className="mt-[54px] flex flex-col-reverse items-start justify-between gap-y-[20px] sm:gap-[30px] lg:flex-row">
          {/* Left side data */}
          <div className="grid w-full grid-cols-1 gap-[20px] md:grid-cols-2 lg:gap-[10px] xl:gap-[30px]">
            <div className="flex flex-row items-start justify-between rounded-[16px] px-[25px] py-[20px] text-left shadow-customerShadow lg:flex-row lg:items-start lg:px-[25px] lg:py-[20px] lg:text-left xl:items-end xl:text-left">
              <div>
                <h2 className="font-inter text-[24px] font-semibold text-secondBlack ">
                  {profileData?.totalLoanAmount > -1 ? (
                    `₹${profileData?.totalLoanAmount}`
                  ) : (
                    <span className="text-gray-400">N/A</span>
                  )}
                </h2>
                <p className="font-poppins text-[18px] font-normal text-secondBlack ">
                  Total Loan Received
                </p>
              </div>
              <div>
                <TotalLoanRecivedIcon></TotalLoanRecivedIcon>
              </div>
            </div>
            <div className="flex flex-row items-end justify-between rounded-[16px] px-[25px] py-[20px] text-left shadow-customerShadow lg:flex-row lg:items-start lg:px-[25px] lg:py-[20px] lg:text-left xl:items-end xl:text-left">
              <div>
                <h2 className="font-inter text-[24px] font-semibold text-secondBlack ">
                  {profileData?.TotalLoanPaidAmount > -1 ? (
                    `₹${profileData?.TotalLoanPaidAmount}`
                  ) : (
                    <span className="text-gray-400">N/A</span>
                  )}
                </h2>
                <p className="font-poppins text-[18px] font-normal text-secondBlack ">
                  Total Loan Paid
                </p>
              </div>
              <div>
                <TotalLoanPaidIcon></TotalLoanPaidIcon>
              </div>
            </div>
            <div className="flex flex-row items-end justify-between rounded-[16px] px-[25px] py-[20px] text-left shadow-customerShadow lg:flex-row lg:items-start lg:px-[25px] lg:py-[20px] lg:text-left xl:items-end xl:text-left">
              <div>
                <h2 className="font-inter text-[24px] font-semibold text-secondBlack ">
                  {profileData?.totalLoanCount > -1 ? (
                    `₹${profileData?.totalLoanCount}`
                  ) : (
                    <span className="text-gray-400">N/A</span>
                  )}
                </h2>
                <p className="font-poppins text-[18px] font-normal text-secondBlack ">
                  Total Loan
                </p>
              </div>
              <div>
                <TotalLoanIcon></TotalLoanIcon>
              </div>
            </div>
            <div className="flex flex-row items-end justify-between rounded-[16px] px-[25px] py-[20px] text-left shadow-customerShadow lg:flex-row lg:items-start lg:text-start xl:items-end xl:text-left">
              <div>
                <h2 className="font-inter text-[24px] font-semibold text-secondBlack ">
                  {profileData?.customerBarge || (
                    <span className="text-gray-400">N/A</span>
                  )}
                </h2>
                <p className="font-poppins text-[18px] font-normal text-secondBlack ">
                  Status
                </p>
              </div>
              <div>
                <StatusIcon></StatusIcon>
              </div>
            </div>
          </div>

          {/* Right side image */}
          <div className="mt-[10px] w-full max-w-[220px] rounded-xl border shadow-lg lg:scale-110">
            <img
              src={
                profileData?.user_image
                  ? profileData?.user_image
                  : "https://t4.ftcdn.net/jpg/01/07/43/45/360_F_107434511_iarF2z88c6Ds6AlgtwotHSAktWCdYOn7.jpg"
              }
              alt="Customer"
              className="!h-[215px] w-full rounded-xl object-cover"
            />
          </div>
        </div>

        {currentPath === "collection-agent" ? (
          <div className="mt-[50px] grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:gap-[40px]">
            <div>
              <Link
                to={`/${currentPath}/profile/${encodeURIComponent(
                  profileData?.id
                )}/loan-list`}
                className="customerInfoBtn lg:[15px] flex w-full items-center justify-center rounded-[8px] border-[#E7B12B] bg-[#E7B12B33] p-[10px] font-poppins text-[15px] font-medium leading-[23.4px] text-[#E9AA0B] lg:text-[16px] xl:p-[16px] xl:text-[18px]"
              >
                <span className="mr-[10px]">
                  <BookMarkIcon />
                </span>
                <span>View Pending Loan</span>
              </Link>
            </div>

            <div>
              <Link
                to={`/${currentPath}/profile/${encodeURIComponent(
                  profileData?.id
                )}/login-history`}
                className="customerInfoBtn lg:[15px] flex w-full items-center justify-center rounded-[8px] border-[#3B76E1] bg-[#3B76E133] p-[10px] font-poppins text-[15px] font-medium leading-[23.4px] text-[#3B76E1] lg:text-[16px] xl:p-[16px] xl:text-[18px]"
              >
                <span className="mr-[10px]">
                  <PageIcon />
                </span>
                Logins
              </Link>
            </div>

            <div>
              <button className="customerInfoBtn lg:[15px] flex w-full items-center justify-center rounded-[8px] bg-[#f768684d] p-[10px] font-poppins text-[15px] font-medium leading-[23.4px] text-[#F76868] lg:text-[16px] xl:p-[16px] xl:text-[18px]">
                <span className="mr-[10px]">
                  <BlockIcon></BlockIcon>
                </span>
                Block
              </button>
            </div>
          </div>
        ) : (
          <div
            className={`mt-[50px] grid grid-cols-1 gap-4 md:grid-cols-2  xl:gap-[40px] ${
              location.pathname === "/crm/user-profile"
                ? "lg:grid-cols-2"
                : "lg:grid-cols-2"
            }`}
          >
            <div>
              <p className="mb-[8px] font-inter text-[15px] font-medium leading-[23.4px] text-secondBlack lg:text-[16px] xl:text-[18px]">
                Customers’s Personal Data
              </p>
              <Link
                to={`/user-profile/profile/${encodeURIComponent(
                  profileData?.user_id
                )}/media-contact-data`}
                className="customerInfoBtn lg:[15px] flex w-full items-center justify-center rounded-[8px] bg-[#F75F00] p-[10px] font-poppins text-[15px] font-medium leading-[23.4px] text-white lg:text-[16px] xl:p-[16px] xl:text-[18px]"
              >
                <span className="mr-[10px]">
                  <GalleryIcon></GalleryIcon>
                </span>
                <span>Media & Contact</span>
              </Link>
            </div>

            <div>
              <p className="mb-[8px] font-inter text-[15px] font-medium leading-[23.4px] text-secondBlack lg:text-[16px] xl:text-[18px]">
                Call & Message History
              </p>
              <Link
                to={`/user-profile/profile/${encodeURIComponent(
                  profileData?.user_id
                )}/call-message-history`}
                className="customerInfoBtn lg:[15px] flex w-full items-center justify-center rounded-[8px] bg-[#398CC5] p-[10px] font-poppins text-[15px] font-medium leading-[23.4px] text-white lg:text-[16px] xl:p-[16px] xl:text-[18px]"
              >
                <span className="mr-[10px]">
                  <CallIcon></CallIcon>
                </span>
                Call & Message
              </Link>
            </div>
          </div>
        )}
      </div>
      <SmallModal
        title={modalTitle}
        modalOpen={smallModalOpen}
        setModalOpen={setSmallModalOpen}
        modalContent={modalContent}
      />
    </Fragment>
  );
};

export default CustomerProfileHeader;
