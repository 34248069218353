import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import { ENDPOINT } from "../App/config/endpoint";
import toast from "react-hot-toast";

export const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
  const token = JSON.parse(localStorage.getItem("agentToken"))?.token;

  const [recoverToken, setRecoverToken] = useState(
    localStorage.getItem("recoverToken")
  );
  const [notFollowedUp, setNotFollowedUp] = useState(0);
  const [followedUp, setFollowedUp] = useState(0);
  const [settled, setSettled] = useState(0);

  const [settledList, setSettledList] = useState([]);
  const [followedUpList, setFollowedUpList] = useState([]);
  const [notFollowedUpList, setNotFollowedUpList] = useState([]);

  // Pagination state
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    itemsPerPage: 10,
  });

  // Handle page changes
  const changePage = (newPage) => {
    if (newPage >= 1 && newPage <= pagination.totalPages) {
      updatePagination(newPage, pagination.totalPages);
    }
  };

  // Update pagination
  const updatePagination = (currentPage, totalPages) => {
    setPagination((prev) => ({
      ...prev,
      currentPage,
      totalPages,
    }));
  };

  // Fetch agent details
  // const getAgentDetailsList = () => {
  //   if (!token) return;

  //   const apiUrl = `${process.env.REACT_APP_MAIN_URL}${ENDPOINT.agent.agentDetails}`;
  //   const postData = { agent_id: "" };
  //   const headers = {
  //     "Content-Type": "application/json",
  //     Authorization: `Bearer ${token}`,
  //   };

  //   axios
  //     .post(apiUrl, postData, { headers })
  //     .then((response) => {
  //       const detailsData = response.data.data;
  //       setAgentDetails(detailsData);
  //       setInputValue(detailsData.mobile_code);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching agent details:", error);
  //     });
  // };

  // Fetch settled loan list
  const getCloseLoanList = () => {
    if (!token) return;
    const apiUrl = `${process.env.REACT_APP_MAIN_URL}${ENDPOINT.loan.loanList}`;
    const postData = {
      perpage: pagination.itemsPerPage.toString(),
      page: pagination.currentPage.toString(),
      loan_status: "close",
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(apiUrl, postData, { headers })
      .then((res) => {
        setSettledList(res.data.data);
      })
      .catch((err) => {
        console.error(
          "Error fetching settled loans:",
          err.response?.data?.error
        );
        if (err.response?.data?.error) {
          toast.error("Token expired, please login again");
          setTimeout(() => {
            localStorage.removeItem("agentToken");
            window.location.href = "/crm-login";
          }, 500);
        }
      });
  };

  // Settled Loan List

  // Fetch followed-up list
  const getFollowedUpList = () => {
    if (!token) return;

    const apiUrl = `${process.env.REACT_APP_MAIN_URL}${ENDPOINT.loan.loanList}`;
    const postData = {
      perpage: pagination.itemsPerPage.toString(),
      page: pagination.currentPage.toString(),
      loan_status: "open",
      followed_up: 1,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    axios
      .post(apiUrl, postData, { headers })
      .then((res) => {
        setFollowedUpList(res.data.data);
      })
      .catch((err) => {
        console.error("Error fetching followed-up loans:", err);
      });
  };

  // Fetch not-followed-up list
  const getNotFollowedUpList = () => {
    if (!token) return;

    const apiUrl = `${process.env.REACT_APP_MAIN_URL}${ENDPOINT.loan.loanList}`;
    const postData = {
      perpage: pagination.itemsPerPage.toString(),
      page: pagination.currentPage.toString(),
      loan_status: "open",
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    axios
      .post(apiUrl, postData, { headers })
      .then((res) => {
        setNotFollowedUpList(res.data.data);
      })
      .catch((err) => {
        console.error("Error fetching not-followed-up loans:", err);
      });
  };

  // Load loan data on mount
  useEffect(() => {
    getCloseLoanList();
    getFollowedUpList();
    getNotFollowedUpList();
  }, []);

  // Auth info for context
  const authInfo = {
    token,
    setRecoverToken,
    recoverToken,
    setNotFollowedUp,
    setFollowedUp,
    notFollowedUp,
    setSettled,
    followedUp,
    settled,
    followedUpList,
    notFollowedUpList,
    settledList,
    pagination,
    setPagination,
    changePage,
  };

  return (
    <AuthContext.Provider value={authInfo}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
