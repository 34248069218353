import MediaContactDetails from "../../Component/CRM/MediaContact/MediaContactDetails";
import UserProfileNav from "../../Component/CRM/SharedComponent/UserProfileNav";
import CustomerProfileHeaderNav from "../../Component/smallComponent/CustomerProfileHeaderNav";

const MediaContact = () => {
  return (
    <div className="min-h-screen bg-[#F0F2F5]">
      {/* <NavBar></NavBar> */}
      <div className="mx-auto mt-[24px] max-w-[1300px]">
        <UserProfileNav></UserProfileNav>
        <div className="bg-white px-[50px] py-[25px]">
          <div className="mb-[24px]">
            <CustomerProfileHeaderNav />
          </div>
          <MediaContactDetails></MediaContactDetails>
        </div>
      </div>
    </div>
  );
};

export default MediaContact;
