import React from "react";
import {
  DownArrowBlueIcon,
  DownArrowGreenIcon,
  UpArrowBlueIcon,
  XArrowRedIcon,
} from "../../../Assets/userProfile/userProfile";

const CallHistory = ({ call_history }) => {
  return (
    <div className="w-full  bg-white">
      <div className="w-full bg-black px-[20px] py-2">
        <h1 className="font-inter text-[22px] font-medium leading-[41.6px] text-white">
          Call History
        </h1>
      </div>

      {call_history ? (
        <div className="my-4 h-full max-h-[700px] gap-y-2 overflow-y-scroll px-3 lg:pr-5 xl:mx-[10px] xl:mr-4">
          {call_history?.map((msg, i) => {
            return (
              <div className="mb-4 flex items-center justify-between gap-2">
                <div className="h-full">
                  <h3 className="mb-2 font-inter text-[18px] font-medium leading-[31.2px] text-[#000] ">
                    {msg?.name}
                  </h3>
                  <p className="font-inter text-[18px] font-medium leading-[31.2px] text-[#000] ">
                    {msg?.phone}
                  </p>
                </div>
                <div className="flex h-full flex-col items-end gap-5 gap-y-[11px]">
                  <p className="p-[3px] font-inter text-[14px] font-medium leading-[21.78px] text-black">
                    {msg?.call_time}
                  </p>
                  {msg?.incoming_outgoing === "incoming" ? (
                    <p className="flex items-center gap-2 p-[3px]">
                      <span>
                        <DownArrowGreenIcon />
                      </span>
                      <span className="font-inter text-[14px] font-medium leading-[21.78px] text-black">
                        {msg?.incoming_outgoing}
                      </span>
                    </p>
                  ) : msg?.incoming_outgoing === "outgoing" ? (
                    <p className="flex items-center gap-2 p-[3px]">
                      <span className="r">
                        <UpArrowBlueIcon />
                      </span>
                      <span className="font-inter text-[14px] font-medium leading-[21.78px] text-black">
                        {msg?.incoming_outgoing}
                      </span>
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="mx-auto w-fit py-[20px]">
          <span className="text-center font-poppins text-[16px] text-gray-400">
            No data is available
          </span>
        </div>
      )}
    </div>
  );
};

export default CallHistory;
