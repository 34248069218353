import React, { useContext, useEffect } from "react";
import DemoTable from "../../smallComponent/DemoTable";
import SharedTable from "../../Shared/SharedTable";
import MyTaskTable from "./MyTaskTable";
import { AuthContext } from "../../../Provider/AuthProvider";

const tableHead = [
  { id: 1, name: "Order ID", width: 95 },
  { id: 2, name: "Product Name", width: 135 },
  { id: 3, name: "Applicant", width: 100 },
  { id: 33, name: "Phone", width: 100 },
  { id: 4, name: "Loan Status", width: 135 },
  { id: 5, name: "Repayment Willingness", width: 215 },
  { id: 6, name: "Next Follow-Up Time", width: 195 },
];

const Settled = () => {
  const { setSettled, settledList } = useContext(AuthContext);

  useEffect(() => {
    setSettled(settledList?.length);
  }, []);

  const settledData = settledList.map(
    ({
      loan_details: {
        id,
        user_id,
        order_no,
        payment_status,
        loan_status,
        closing_date,
        collection_stage,
        followup_result,
        next_followup_time,
        user_details: {
          user_no,
          user_image,
          full_name,
          mobile_code,
          mobile_no,
        },
        product_details: { name, loan_amount, start_date },
      },
    }) => ({
      id,
      user_id,
      order_no,
      name,
      full_name,
      phone: `${mobile_code}${mobile_no}`,
      loan_status,
      followup_result,
      next_followup_time,
    })
  );
  return (
    <div>
      <MyTaskTable
        tableData={settledData}
        tableHead={tableHead}
        searchMethod="userName"
        operations={false}
      />
    </div>
  );
};

export default Settled;
