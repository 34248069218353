import React, { useContext, useState } from "react";
import NavBar from "../../Component/Shared/NavBar";
import MyTaskFilter from "../../Component/smallComponent/MyTaskFilter";
import NotFollowedUp from "../../Component/CRM/MyTask/NotFollowedUp";
import FollowingUp from "../../Component/CRM/MyTask/FollowingUp";
import Settled from "../../Component/CRM/MyTask/Settled";
import { AuthContext } from "../../Provider/AuthProvider";
// import FullWidthTabs from "../../Component/smallComponent/FullWidthTabs";

const MyTask = () => {
  const {notFollowedUp, followedUp, settled} = useContext(AuthContext);
  const [tabSelect, setTabSelect] = useState(0);

  const handleTabSelected = (tabIndex) => {
    setTabSelect(tabIndex);
  };
  return (
    <div className="min-h-screen bg-[#F0F2F5] pb-[22px]">
      <NavBar />
      <div className="mx-auto mt-[24px] max-w-[1300px]">
        <div className="bg-white px-[20px] py-[30px] rounded-lg">
          <div className="custom-tab-border flex overflow-x-auto overflow-y-hidden whitespace-nowrap ">
            <button
              onClick={() => handleTabSelected(0)}
              className={`-mb-px inline-flex h-10 items-center whitespace-nowrap ${
                tabSelect === 0
                  ? "border-b-[3px] border-[#F75F00] font-bold text-[#F75F00]"
                  : "border-b-[3px] border-[white] font-medium text-[black]"
              } bg-transparent px-2 sm:px-4 text-center font-inter text-sm focus:outline-none`}
            >
              Not Followed Up <span className={`${tabSelect === 0 ? 'block' : 'hidden'}`}>({notFollowedUp})</span>
            </button>

            <button
              onClick={() => handleTabSelected(1)}
              className={`-mb-px inline-flex h-10 items-center whitespace-nowrap ${
                tabSelect === 1
                  ? "border-b-[3px] border-[#F75F00] font-bold text-[#F75F00]"
                  : "border-b-[3px] border-[white] font-medium text-[black]"
              } bg-transparent px-2 sm:px-4 text-center font-inter text-sm focus:outline-none`}
            >
              Following Up <span className={`${tabSelect === 1 ? 'block' : 'hidden'}`}>({followedUp})</span>
            </button>

            <button
              onClick={() => handleTabSelected(2)}
              className={`-mb-px inline-flex h-10 items-center whitespace-nowrap ${
                tabSelect === 2
                  ? "border-b-[3px] border-[#F75F00] font-bold text-[#F75F00]"
                  : "border-b-[3px] border-[white] font-medium text-[black]"
              }  bg-transparent px-2 sm:px-4 text-center font-inter text-sm focus:outline-none`}
            >
              Settled <span className={`${tabSelect === 2 ? 'block' : 'hidden'}`}>({settled})</span>
            </button>
          </div>

          {/* Tab Panel */}
          <div className="mt-[20px]">
            {
                tabSelect === 0 && <NotFollowedUp />
            }
            {
                tabSelect === 1 && <FollowingUp />
            }
            {
                tabSelect === 2 && <Settled />
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyTask;
