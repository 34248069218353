import React, { useContext, useEffect } from "react";
import DemoTable from "../../smallComponent/DemoTable";
import SharedTable from "../../Shared/SharedTable";
import MyTaskTable from "./MyTaskTable";
import { AuthContext } from "../../../Provider/AuthProvider";

const tableHead = [
  { id: 1, name: "Order ID", width: 95 },
  { id: 2, name: "Product Name", width: 135 },
  { id: 3, name: "Applicant", width: 100 },
  { id: 10, name: "Phone", width: 100 },
  { id: 4, name: "Collection Stage", width: 135 },
  { id: 5, name: "Last Follow-Up Result", width: 215 },
  { id: 6, name: "Next Follow-Up Time", width: 195 },
  { id: 7, name: "Operation", width: 90 },
];

const NotFollowedUp = () => {
  const { setNotFollowedUp, notFollowedUpList } = useContext(AuthContext);

  useEffect(() => {
    setNotFollowedUp(notFollowedUpList?.length);
  }, []);

  const notFollowedupData = notFollowedUpList.map(
    ({
      loan_details: {
        id,
        user_id,
        order_no,
        payment_status,
        loan_status,
        closing_date,
        collection_stage,
        followup_result,
        user_details: {
          user_no,
          user_image,
          full_name,
          mobile_code,
          mobile_no,
          next_followup_time,
        },
        product_details: { name, loan_amount, start_date },
      },
    }) => ({
      id,
      user_id,
      order_no,
      name,
      full_name,
      phone: `${mobile_code}${mobile_no}`,
      collection_stage,
      followup_result,
      next_followup_time,
    })
  );

  console.log('hello',notFollowedupData)
  return (
    <div>
      <MyTaskTable tableData={notFollowedupData} tableHead={tableHead} />
    </div>
  );
};

export default NotFollowedUp;
