import React, { Fragment } from "react";
import { Link, useLocation, useParams } from "react-router-dom";

const CustomerProfileHeaderNav = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  const { id } = useParams();
  return (
    <Fragment>
      <h1 className="mb-2 font-poppins text-[23px] font-semibold text-secondBlack md:text-[24px] lg:text-[30px]">
        Customer Profile
      </h1>
      <ol className="breadcrumb flex flex-col pl-[2px] xs:flex-row xs:gap-3">
        <Link
          to="/my-task"
          className="breadcrumb-item font-poppins text-[15px] font-medium capitalize text-blue-500 hover:underline md:text-[17px]"
        >
          <span className="mr-1 inline text-gray-500 xs:hidden">{">"}</span>
          My Task
        </Link>
        {pathnames.map((name, index) => {
          const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
          const isLast = index === pathnames.length - 1;
          const displayName =
            name.length > 20
              ? name.replace(name, "").replace("-", " ")
              : name.replace("-", " ");

          const locationName = displayName.replace("-", " ");

          if (locationName === "crm" && index === 0) {
            // Skip rendering "Crm" when it's the first part of the path
            return null;
          }

          return (
            <li
              className="breadcrumb-item !ml-0 mt-[1px] flex items-center font-poppins text-[15px] font-medium capitalize md:text-[17px]"
              key={name}
            >
              {locationName ? (
                <span className="mr-1 text-gray-500">{">"}</span>
              ) : (
                ""
              )}
              {isLast ? (
                <span className="text-gray-500">{locationName}</span>
              ) : name.length > 20 ? (
                <span className="text-red-500">{locationName}</span>
              ) : (
                <Link
                  to={
                    locationName === "loan request" ||
                    locationName === "profile" ||
                    locationName === "setting" ||
                    locationName === "loan" ||
                    locationName === id
                      ? ""
                      : locationName === "user profile"
                      ? `/user-profile/${id}` // Set the destination when locationName is "user profile"
                      : routeTo
                  }
                  className={`${
                    locationName === "loan request" ||
                    locationName === "profile" ||
                    locationName === "setting" ||
                    locationName === "loan" ||
                    locationName === id
                      ? "text-gray-500"
                      : "text-blue-500 hover:underline"
                  }`}
                >
                  {locationName}
                </Link>
              )}
            </li>
          );
        })}
      </ol>
    </Fragment>
  );
};

export default CustomerProfileHeaderNav;
