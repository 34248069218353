export const PATH = "api";

export const ENDPOINT = {
  auth: {
    adminLogin: "api/v1/admin/login",
    changePassword: "api/v1/admin/change-password",
    recoverAccount: "api/v1/admin/check-admin",
  },

  logout: {
    userLogout: "",
  },
  product: {
    productList: "api/v1/product/list",
    productDetails: "api/v1/product/details",
    productUpdate: "api/v1/product/update",
    productCreate: "api/v1/product/create",
    productDelete: "api/v1/product/delete",
  },
  info: {
    galleryData: "api/v1/info/gallery/list",
    contactData: "api/v1/info/contacts/list",
    callLogData: "api/v1/info/call-logs/list",
    messageData: "api/v1/info/messages/list",
  },
  user: {
    userList: "api/v1/user/list",
    userBlockUnblock: "api/v1/user/block-unblock",
    userDetails: "api/v1/user/details",
  },
  agent: {
    // agentDetails: "api/v1/agent/details",
    agentList: "api/v1/agent/list",
    agentUpdate: "api/v1/agent/update",
    agentDetails: "api/v1/agent/details",
    createAgent: "api/v1/agent/create",
    agentLogin: "api/v1/agent/login",
    agentNewPass: "api/v1/agent/change-password",
    agentRecover: "api/v1/agent/check-agent",
  },
  loan: {
    loanList: "api/v1/loan/list",
    updateLoan: "api/v1/loan/update",
    loanDetails: "api/v1/loan/details",
    updateLoanReq: "api/v1/loan/request/update",
    reminder: "api/v1/loan/reminder",
    closeLoan: "api/v1/loan/close"
  },
  payment: {
    rollover: "api/v1/payment/rollover",
    repayment: "api/v1/payment/repayment",
  },
  appApi: {
    AddLink: "api/v1/web/applink",
  }
};
