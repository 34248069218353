import React, { useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { twMerge } from 'tailwind-merge';

const Print = ({ children, className, printStart, setPrintStart }) => {

  // console.log(printStart);
  
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'invoice',
    onAfterPrint: () => setPrintStart(false),
  });

  useEffect(() => {
    if (printStart) {
      handlePrint();
    }
  }, [printStart, handlePrint]);

  return (
    <div
      ref={componentRef}
      style={{ width: '100%' }}
      className={twMerge(`${printStart ? 'p-10 duration-300' : ''}`, className)}
    >
      {children}
    </div>
  );
};

export default Print;