import React, { useContext, useEffect, useState } from "react";
import '../../Styles/Auth/createNewPass.css'
import { FiEye, FiEyeOff } from "react-icons/fi";
import { Link, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import { ENDPOINT } from "../../App/config/endpoint";
import HotToaster from "../../Component/Shared/HotToaster";
import { AuthContext } from "../../Provider/AuthProvider";

const CreateNewPass = () => {
  const navigate = useNavigate();
  const {recoverToken} = useContext(AuthContext);

  const [showNewPass, setShowNewPass] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [createPassInfo, setCreatePassInfo] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const handleChangePassword = (e) => {
    setIsLoading(true);
    e.preventDefault();

    const form = e.target;

    const newPass = form?.newPassword?.value;
    const confirmPass = form?.confirmPassword?.value;
    if (newPass === "" || confirmPass === "") {
      setIsLoading(false);
      return toast.error("Please type your password!");
    }
    if(newPass !== confirmPass){
      setIsLoading(false);
      return toast.error("Password doesn't match!");
    }

    const apiUrl = `${process.env.REACT_APP_MAIN_URL}${ENDPOINT.agent.agentNewPass}`;
    const postData = {
      password: confirmPass,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${recoverToken}`,
    };

    if (!recoverToken) {
      form.reset();
      setIsLoading(false);
      navigate('/crm-login')
      return;
    } else {
      axios
        .post(apiUrl, postData, { headers })
        .then((res) => {
          
          toast.success(res.data.message);
          if (res.data.type === "error") {
            // console.log("Password changes but error", res);
            toast.error(res.data.message);
            setIsLoading(false);
            form.reset();
          } else {
            // console.log("Password changes", res);
            form.reset();
            setIsLoading(false);
            navigate("/crm-login");
            localStorage.removeItem('recoverToken');
          }
        })
        .catch((e) => {
        // console.log("eeeee", e);
          // UnAuth(e);
          toast.error("Network Error");
          form.reset();
          setIsLoading(false);
        });
    }
  };
  useEffect(() => {
    if (!recoverToken) {
      // console.log("consol");
      navigate("/crm-login");
    }
  }, [recoverToken]);
  return (
    
    <div className="flex min-h-screen w-full items-center justify-center bg-white py-5">
      <div className="mx-auto min-w-[250px] rounded-b-lg rounded-t-xl bg-black shadow-loginShadow md:min-w-[400px] lg:min-w-[600px]">
        <div className="create_new_pass_banner rounded-lg pb-[5.938rem] pt-[5.820rem]">
          <div className="px-3 text-center">
            <h2 className=" font-poppins text-[2rem] font-semibold leading-[2.6rem] text-black">
              Create New Password
            </h2>
          </div>
        </div>
        <form action="" onSubmit={handleChangePassword}>
          <div className="px-[1rem] pb-[5rem] pt-[4.063rem] md:px-[2.125rem] lg:px-[3.125rem]">
            <div className="space-y-5">
              <div className="flex flex-col gap-2">
                <label
                  className="inter-500 font-inter text-base leading-[1.3rem] text-white"
                  htmlFor="NewPassword"
                >
                  New Password<span className="text-[#D3AC46]">*</span>
                </label>
                <div className="relative w-full">
                  <input
                    className="w-full rounded border-[0.5px] border-[#fff] bg-transparent px-4 py-2.5 font-poppins text-lg font-normal leading-[1.463rem] text-[#fff]"
                    type={`${showNewPass ? "text" : "password"}`}
                    name="newPassword"
                    placeholder="Enter New Password"
                  />
                  {showNewPass ? (
                    <>
                      <p
                        onClick={() => setShowNewPass(!showNewPass)}
                        className="absolute right-[16px] top-[15px] cursor-pointer text-[18px] text-white"
                      >
                        <FiEyeOff />
                      </p>
                    </>
                  ) : (
                    <>
                      <p
                        onClick={() => setShowNewPass(!showNewPass)}
                        className="absolute right-[16px] top-[15px] cursor-pointer text-[18px] text-white"
                      >
                        <FiEye />
                      </p>
                    </>
                  )}
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <label
                  className="inter-500 font-inter text-base leading-[1.3rem] text-white"
                  htmlFor="ConfirmPassword"
                >
                  Confirm Password<span className="text-[#D3AC46]">*</span>
                </label>
                <div className="relative w-full">
                  <input
                    className="w-full rounded border-[0.5px] border-[#fff] bg-transparent px-4 py-2.5 font-poppins text-lg font-normal leading-[1.463rem] text-[#fff]"
                    type={`${showConfirmPass ? "text" : "password"}`}
                    name="confirmPassword"
                    placeholder="Re-type Password"
                  />
                  {showConfirmPass ? (
                    <>
                      <p
                        onClick={() => setShowConfirmPass(!showConfirmPass)}
                        className="absolute right-[16px] top-[15px] cursor-pointer text-[18px] text-white"
                      >
                        <FiEyeOff />
                      </p>
                    </>
                  ) : (
                    <>
                      <p
                        onClick={() => setShowConfirmPass(!showConfirmPass)}
                        className="absolute right-[16px] top-[15px] cursor-pointer text-[18px] text-white"
                      >
                        <FiEye />
                      </p>
                    </>
                  )}
                </div>
              </div>
              <div className="flex flex-wrap items-center justify-between">
                <div className="flex flex-wrap items-center">
                  <Link
                    to="/crm-login"
                    className="cursor-pointer font-poppins text-base font-medium leading-[1.3rem] tracking-[0.32px] text-[#fff] underline hover:text-blue-500 hover:underline hover:underline-offset-2"
                  >
                    Login Here
                  </Link>
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  className="w-full bg-white p-2.5 font-inter text-base font-semibold leading-[1.3rem] tracking-[0.32px] text-black"
                >
                  {isLoading ? 'Changing...' : 'Submit'}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateNewPass;
