import React from "react";
import toast from "react-hot-toast";
import { FiCopy } from "react-icons/fi";

const CustomerLoanDetails = ({ loanDetails, profileData }) => {
  console.log(loanDetails);
  console.log(profileData);

  const handleCopy = (link) => {
    navigator.clipboard
      .writeText(link)
      .then(() => {
        toast.success("Link copied to clipboard");
      })
      .catch((error) => {
        toast.error("Failed to copy link");
        console.error("Copy error: ", error);
      });
  };
  return (
    <div className="">
      {/* Top Nav */}
      <div className="">
        <h1 className="py-8 pb-[10px] font-inter text-[23px] font-semibold leading-[44.8px] text-secondBlack md:pb-[20px] md:text-[26px] lg:py-8">
          Loan Details
        </h1>
      </div>

      {/* Loan Information */}
      <div className="grid grid-cols-1 gap-[20px] pb-[10px] md:grid-cols-2 md:pb-[20px] lg:grid-cols-3 lg:gap-6 lg:pb-8">
        <div className="row-span-full">
          <h3 className="font-inter text-[18px] font-medium  leading-[29.05px] text-secondBlack md:text-[21px]">
            Loan Start
          </h3>
          <div className="mt-[8px] flex min-h-[50px] items-center rounded-lg border px-[16px] font-inter text-[18px] font-normal capitalize leading-[29.05px] text-secondBlack  shadow-smallShadow md:mt-[12px] md:text-[21px] lg:mt-4">
            {loanDetails?.created_at || (
              <span className="text-[18px] text-gray-400">N/A</span>
            )}
          </div>
        </div>
        <div>
          <h3 className="font-inter text-[18px] font-medium  leading-[29.05px] text-secondBlack md:text-[21px]">
            Loan End
          </h3>
          <div className="mt-[8px] flex min-h-[50px] items-center justify-between rounded-lg border px-[16px] font-inter text-[18px] font-normal capitalize leading-[29.05px] text-secondBlack  shadow-smallShadow md:mt-[12px] md:text-[21px] lg:mt-4">
            {loanDetails?.closing_date || (
              <span className="text-[18px] text-gray-400">N/A</span>
            )}
          </div>
        </div>
        <div>
          <h3 className="font-inter text-[18px] font-medium  leading-[29.05px] text-secondBlack md:text-[21px]">
            Due Loan
          </h3>
          <div
            onClick={() => handleCopy(loanDetails?.due_amount)}
            className="mt-[8px] flex min-h-[50px] items-center justify-between rounded-lg border px-[16px] font-inter text-[18px] font-normal leading-[29.05px] text-secondBlack  shadow-smallShadow md:mt-[12px] md:text-[21px] lg:mt-4"
          >
            {loanDetails?.due_amount || (
              <span className="text-[18px] text-gray-400">N/A</span>
            )}
            <FiCopy className="h-5 w-5" />
          </div>
        </div>
        <div>
          <h3 className="font-inter text-[18px] font-medium  leading-[29.05px] text-secondBlack md:text-[21px]">
            Loan Status
          </h3>
          <div className="mt-[8px] flex min-h-[50px] items-center justify-between rounded-lg border px-[16px] font-inter text-[18px] font-normal leading-[29.05px] text-secondBlack  shadow-smallShadow md:mt-[12px] md:text-[21px] lg:mt-4">
            {loanDetails?.loan_status || (
              <span className="text-[18px] text-gray-400">N/A</span>
            )}
          </div>
        </div>
        <div>
          <h3 className="font-inter text-[18px] font-medium  leading-[29.05px] text-secondBlack md:text-[21px]">
            Payment Status
          </h3>
          <div className="mt-[8px] flex min-h-[50px] items-center justify-between rounded-lg border px-[16px] font-inter text-[18px] font-normal leading-[29.05px] text-secondBlack  shadow-smallShadow md:mt-[12px] md:text-[21px] lg:mt-4">
            {loanDetails?.payment_status || (
              <span className="text-[18px] text-gray-400">N/A</span>
            )}
          </div>
        </div>
        <div>
          <h3 className="font-inter text-[18px] font-medium  leading-[29.05px] text-secondBlack md:text-[21px]">
            Loan Request Status
          </h3>
          <div className="mt-[8px] flex min-h-[50px] items-center justify-between rounded-lg border px-[16px] font-inter text-[18px] font-normal leading-[29.05px] text-secondBlack  shadow-smallShadow md:mt-[12px] md:text-[21px] lg:mt-4">
            {loanDetails?.request_status || (
              <span className="text-[18px] text-gray-400">N/A</span>
            )}
          </div>
        </div>
        <div>
          <h3 className="font-inter text-[18px] font-medium  leading-[29.05px] text-secondBlack md:text-[21px]">
            Interest Amount
          </h3>
          <div className="mt-[8px] flex min-h-[50px] items-center justify-between rounded-lg border px-[16px] font-inter text-[18px] font-normal leading-[29.05px] text-secondBlack  shadow-smallShadow md:mt-[12px] md:text-[21px] lg:mt-4">
            {loanDetails?.interest_amount || (
              <span className="text-[18px] text-gray-400">N/A</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerLoanDetails;
