import React from "react";
import toast from "react-hot-toast";

const Gallery = ({ gallery }) => {
  console.log(gallery?.data?.data);

  return (
    <div className="w-full bg-white lg:min-w-[690px]">
      <div className="w-full bg-black px-[20px] py-2">
        <h1 className="font-inter text-[22px] font-medium leading-[41.6px] text-white">
          Gallery
        </h1>
      </div>
      {gallery?.data?.data?.length > 0 ? (
        <div className="m-4 grid max-h-[700px] grid-cols-1 gap-3 overflow-y-scroll pr-2 sm:grid-cols-2 md:grid-cols-3">
          {gallery?.data?.data?.map((image, i) => {
            return (
              <img
                key={i}
                className="h-full w-full rounded-[8px] object-cover xl:max-h-[215px] xl:min-h-[215px] xl:min-w-[204px] xl:max-w-[204px]"
                src={image?.data}
                alt="Gallery"
              />
            );
          })}
        </div>
      ) : (
        <div className="mx-auto w-fit py-[20px]">
          <span className="text-center font-poppins text-[16px] text-gray-400">
            No data is available
          </span>
        </div>
      )}
    </div>
  );
};

export default Gallery;
