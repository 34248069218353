import React from "react";

const BasicInformation = ({ profileData }) => {
  return (
    <div className="">
      {/* Top Nav */}
      <div className="">
        <h1 className="py-8 pb-[10px] font-inter text-[23px] font-medium leading-[44.8px] text-secondBlack md:pb-[20px] md:text-[26px] lg:py-8 ">
          Basic Information
        </h1>
      </div>

      {/* Loan Information */}
      <div className="grid grid-cols-1 gap-[20px] pb-[10px] md:grid-cols-2 md:pb-[20px] lg:grid-cols-3 lg:gap-6 lg:pb-8">
        <div className="row-span-full">
          <h3 className="font-inter text-[18px] font-medium leading-[29.05px] text-secondBlack md:text-[21px] ">
            Occupation
          </h3>
          <div className="border capitalize mt-[8px] rounded-lg px-[16px] min-h-[50px] flex items-center font-inter text-[18px] font-normal leading-[29.05px] text-secondBlack shadow-smallShadow   md:text-[21px]  ">
            {profileData?.occupation || <span className="text-[18px] text-gray-400">N/A</span>}
          </div>
        </div>
        <div>
          <h3 className="capitalize font-inter text-[18px] font-medium leading-[29.05px] text-secondBlack md:text-[21px] ">
            Education
          </h3>
          <div className="border capitalize mt-[8px] rounded-lg px-[16px] min-h-[50px] flex items-center font-inter text-[18px] font-normal leading-[29.05px] text-secondBlack shadow-smallShadow   md:text-[21px]  ">
            {profileData?.education || <span className="text-[18px] text-gray-400">N/A</span>}
          </div>
        </div>
        <div className="">
          <h3 className="font-inter text-[18px] font-medium leading-[29.05px] text-secondBlack md:text-[21px] ">
            Type Of Residence
          </h3>
          <div className="border capitalize mt-[8px] rounded-lg px-[16px] min-h-[50px] flex items-center font-inter text-[18px] font-normal leading-[29.05px] text-secondBlack shadow-smallShadow   md:text-[21px]  ">
            {profileData?.residence_type || <span className="text-[18px] text-gray-400">N/A</span>}
          </div>
        </div>
        <div>
          <h3 className="font-inter text-[18px] font-medium leading-[29.05px] text-secondBlack md:text-[21px] ">
            Salary Range
          </h3>
          <div className="border mt-[8px] rounded-lg px-[16px] min-h-[50px] flex items-center font-inter text-[18px] font-normal leading-[29.05px] text-secondBlack shadow-smallShadow   md:text-[21px]  ">
            {profileData?.salary_range || <span className="text-[18px] text-gray-400">N/A</span>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicInformation;
