import React from "react";
import NavBar from "../../Component/Shared/NavBar";
import UserProfileNav from "../../Component/CRM/SharedComponent/UserProfileNav";
import CustomerProfileHeaderNav from "../../Component/smallComponent/CustomerProfileHeaderNav";
import CustomerProfileHeader from "../../Component/CRM/UserProfile/CustomerProfileHeader";
import LoanRecord from "../../Component/CRM/UserProfile/LoanRecord";
import CustomersInformation from "../../Component/CRM/UserProfile/CustomersInformation";
import BasicInformation from "../../Component/CRM/UserProfile/BasicInformation";
import ContactInformation from "../../Component/CRM/UserProfile/ContactInformation";
import AccountInformation from "../../Component/CRM/UserProfile/AccountInformation";
import DeviceInformation from "../../Component/CRM/UserProfile/DeviceInformation";
import { useParams } from "react-router-dom";
import CommonNavBar from "../../Component/AdminPannel/CommonNavBar";
import { useState } from "react";
import { useEffect } from "react";
import { ENDPOINT } from "../../App/config/endpoint";
import { useContext } from "react";
import { AuthContext } from "../../Provider/AuthProvider";
import axios from "axios";
import toast from "react-hot-toast";
import Print from "../../utils/Print";
import CustomerLoanDetails from "../../Component/CRM/UserProfile/CustomerLoanDetails";
const UserProfile = () => {
  const { token } = useContext(AuthContext);
  const { id } = useParams();
  const loanId = decodeURIComponent(id);
  const [reminderValue, setReminderValue] = useState("10:00");
  const [loanDetails, setLoanDetails] = useState(null);
  const [profileData, setProfileData] = useState(null);
  const [printStart, setPrintStart] = useState(false);

  const getLoanDetails = () => {
    const apiUrl = `${process.env.REACT_APP_MAIN_URL}${ENDPOINT.loan.loanDetails}`;
    const postData = {
      loan_id: loanId,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    axios
      .post(apiUrl, postData, { headers })
      .then((res) => {
        setLoanDetails(res.data.data.loan_details);
      })
      .catch((err) => {
        toast.error("Something went wrong!"); // Agent don't have any permission for this request
        // console.log(err);
      });
  };

  const getUserDetails = () => {
    if (loanDetails) {
      const apiUrl = `${process.env.REACT_APP_MAIN_URL}${ENDPOINT.user.userDetails}`;
      const postData = {
        user_id: loanDetails.user_id,
      };
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      axios
        .post(apiUrl, postData, { headers })
        .then((res) => {
          setProfileData(res.data.data);
        })
        .catch((err) => {
          toast.error("Something went wrong!");
          // console.log(err);
        });
    }
  };

  useEffect(() => {
    getLoanDetails();
  }, [token]);

  useEffect(() => {
    if (loanDetails) {
      getUserDetails();
    }
  }, [loanDetails]);

  console.log(profileData);

  return (
    <div className="min-h-screen bg-[#F0F2F5]">
      <NavBar></NavBar>
      <div className="mx-auto mt-[24px] max-w-[1300px]">
        <UserProfileNav loanDetails={loanDetails}></UserProfileNav>
        <div className="bg-white px-[50px] py-[25px]">
          <CommonNavBar />
          <Print printStart={printStart} setPrintStart={setPrintStart}>
            {profileData === null ? (
              <div className="ml-[-100px] mt-[100px] flex items-center justify-center">
                <span className="auto_loader"></span>
              </div>
            ) : (
              <>
                <CustomerProfileHeader
                  loanDetails={loanDetails}
                  profileData={profileData}
                ></CustomerProfileHeader>
                {/* <LoanRecord
            loanDetails={loanDetails}
            profileData={profileData}
          ></LoanRecord> */}
                <CustomersInformation
                  loanDetails={loanDetails}
                  profileData={profileData}
                  setPrintStart={setPrintStart}
                ></CustomersInformation>
                <BasicInformation
                  loanDetails={loanDetails}
                  profileData={profileData}
                ></BasicInformation>
                <ContactInformation
                  loanDetails={loanDetails}
                  profileData={profileData}
                ></ContactInformation>
                <AccountInformation
                  loanDetails={loanDetails}
                  profileData={profileData}
                ></AccountInformation>
                <CustomerLoanDetails
                  loanDetails={loanDetails}
                  profileData={profileData}
                />
              </>
            )}
            {/* <DeviceInformation></DeviceInformation> */}
          </Print>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
