import { Listbox, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import { BsChevronDown } from "react-icons/bs";
import { TimePicker } from "react-ios-time-picker";
import { AiOutlineFieldTime } from "react-icons/ai";
import { ENDPOINT } from "../../App/config/endpoint";
import axios from "axios";
import { AuthContext } from "../../Provider/AuthProvider";
import { useContext } from "react";
import toast from "react-hot-toast";
const AddReminder = ({ setModalOpen, loanId }) => {
// console.log("loanId", loanId);
  const { token } = useContext(AuthContext);
  const selectOptions1 = [
    { name: "Ability to repay" },
    { name: "Pay to Refuse" },
    { name: "PTP" },
    { name: "Pay Tomorrow" },
    { name: "Threatening to report to the police" },
  ];
  const [selected1, setSelected1] = useState(() => selectOptions1[0]);
  const [reminderLoading, setReminderLoading] = useState(false);

  const [value, setValue] = useState("10:00");

  const onChange = (timeValue) => {
    setValue(timeValue);
  };

// console.log(value);

  const handleAddReminder = () => {
    setReminderLoading(true);
    const apiUrl = `${process.env.REACT_APP_MAIN_URL}${ENDPOINT.loan.reminder}`;

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    const postData = {
      loan_id: loanId,
      followup_result: selected1.name,
      next_followup_time: value,
    };

  // console.log(postData);
    axios
      .post(apiUrl, postData, { headers })
      .then((res) => {
      // console.log(res);
        if (res.data.status) {
          toast.success(res.data.message);
        }
        setReminderLoading(false);
        setModalOpen(false);
      })
      .catch((err) => {
      // console.log(err);
        setReminderLoading(false);
        setModalOpen(false);
      });
  };
  return (
    <div className="mt-8">
      <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
        <div className="w-full">
          <h3 className="font-inter text-[16px] font-medium text-black md:text-[18px]">
            Willingness to repay
          </h3>

          {/* Selection Item 1 in Modal */}
          <Listbox
            value={selected1}
            onChange={setSelected1}
            className=" rounded-lg border border-[#d5d0d0] px-[8px] py-[6px]"
          >
            <div className="relative mt-1">
              <Listbox.Button className="relative w-full cursor-pointer rounded-md bg-white text-left font-inter text-[16px] font-normal text-secondBlack focus:text-secondBlack focus:outline-none md:text-[18px]">
                <span className="block truncate">{selected1.name}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <BsChevronDown
                    className="-mr-1 ml-2 h-5 w-5 text-violet-200 hover:text-violet-100"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute left-0 z-[10000] mt-4 w-full overflow-auto rounded-lg bg-white px-5 py-[10px] text-[16px] shadow-selectShadow md:text-[18px]">
                  {selectOptions1.map((singleOption, singleOptionIdx) => (
                    <Listbox.Option
                      key={singleOptionIdx}
                      className={({ active }) =>
                        `relative cursor-pointer select-none border-b border-gray-200 py-2 pl-2 text-[16px] md:text-[18px] ${
                          active
                            ? "bg-gray-100 text-[#0EAB8B]"
                            : "text-secondBlack"
                        }`
                      }
                      value={singleOption}
                    >
                      {({ selected1 }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected1 ? "font-normal" : "font-normal"
                            }`}
                          >
                            {singleOption.name}
                          </span>
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        </div>
        <div className="relative z-20 w-full">
          <h3 className="mb-1 font-inter text-[16px] font-medium text-black md:text-[18px]">
            Willingness to repay
          </h3>
          <TimePicker onChange={onChange} value={value} className="w-full" />
          <AiOutlineFieldTime
            size={21}
            className="absolute bottom-[10px] right-2 text-[#bab9b9]"
          />
        </div>
      </div>

      <div className="my-6 flex gap-3">
        <button
          onClick={() => setModalOpen(false)}
          className="rounded-[4px] bg-[#EF4E57] px-8 py-[6px] text-base font-medium text-white"
        >
          Cancel
        </button>
        <button
          onClick={handleAddReminder}
          className="rounded-[4px] bg-[#0EAB8B] w-[115px] py-[6px] text-base font-medium text-white"
        >
          {reminderLoading ? "Updating..." : "Update"}
        </button>
      </div>
    </div>
  );
};

export default AddReminder;
