import React from "react";

const Contact = ({ contact }) => {
  console.log(contact);
  return (
    <div className="w-full bg-white">
      <div className="w-full bg-black px-[20px] py-2">
        <h1 className="font-inter text-[22px] font-medium leading-[41.6px] text-white">
          Contact
        </h1>
      </div>

      {contact?.length > 0 ? (
        <div className="m-4 max-h-[700px] overflow-y-scroll">
          {contact?.map((single, i) => {
            return (
              <div key={i}>
                <h3 className="font-inter text-[18px] font-medium capitalize leading-[31.2px] text-[#000]">
                  {single?.full_name || "N/A"}
                </h3>
                {/* <p className="mb-2 font-inter text-[18px] font-normal leading-[31.2px] text-[#000]">
                  {single?.phone || "N/A"}
                </p> */}
                <div>
                  Phone :{" "}
                  {single?.phone_numbers?.length > 0
                    ? single?.phone_numbers?.join(",")
                    : "N/A"}
                </div>
                <div>
                  Email :{" "}
                  {single?.email?.length > 0 ? single?.email?.join(",") : "N/A"}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="mx-auto w-fit py-[20px]">
          <span className="text-center font-poppins text-[16px] text-gray-400">
            No data is available
          </span>
        </div>
      )}
    </div>
  );
};

export default Contact;
