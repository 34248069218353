import React, { Fragment, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../Styles/Auth/login.css";
import { toast } from "react-hot-toast";
import axios from "axios";
import { ENDPOINT } from "../../App/config/endpoint";
import HotToaster from "../../Component/Shared/HotToaster";
import { AuthContext } from "../../Provider/AuthProvider";

const Login = () => {
  const { token } = useContext(AuthContext);
  const userInformation = JSON.parse(localStorage.getItem("agentInfo"));
  // console.log("user info: ", userInformation);
  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(userInformation ? true : false);

  const [LoginInfo, setLoginInfo] = useState({
    email: userInformation ? userInformation.email : "",
    password: userInformation ? userInformation.password : "",
  });
  const navigate = useNavigate();

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  function LoginFun(e) {
    e.preventDefault();
    setIsLoading(true);

    if (isChecked) {
      localStorage.setItem("agentInfo", JSON.stringify(LoginInfo));
    } else {
      localStorage.removeItem("agentInfo");
    }
    // const apiUrl = `https://api.quickcashin.in/api/v1/admin/login`;
    const apiUrl = `${process.env.REACT_APP_MAIN_URL}${ENDPOINT.agent.agentLogin}`;
    console.log(apiUrl);
    axios
      .post(apiUrl, LoginInfo)
      .then((res) => {
        // console.log("response: ", res);
        // console.log("body data: ", LoginInfo);
        if (res.data.type === "error") {
          // console.log("login but error", res);
          toast.error(res.data.message);
          setIsLoading(false);
        } else {
          toast.success(res.data.message);
          localStorage.setItem(
            "agentToken",
            JSON.stringify({
              token: res.data.token,
            })
          );
          window.location.href = "/";
          setIsLoading(false);
        }
      })
      .catch((e) => {
        if (e.response.data.error) {
          toast.error(e.response.data.error);
        }
        // UnAuth(e);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    if (token) {
      navigate("/");
    }
  }, [token]);

  return (
    <Fragment>
      <div className="flex min-h-screen w-full items-center justify-center bg-white py-5">
        <div className="mx-auto min-w-[250px] rounded-b-lg rounded-t-xl bg-black shadow-loginShadow md:min-w-[400px] lg:min-w-[600px]">
          <div className="login_banner rounded-lg pb-[4.938rem] pt-[4.875rem]">
            <div className="px-2 text-center">
              <h2 className="mb-2 font-poppins text-[2rem] font-semibold capitalize leading-[2.6rem] text-black">
                Welcome To Quick Cash
              </h2>
              <h2 className="font-inter text-lg font-medium capitalize leading-[1.463rem] text-black">
                CRM login to Quick Cash Collection System
              </h2>
            </div>
          </div>
          <div className="px-[1rem] pb-[5rem] pt-[4.063rem] md:px-[2.125rem] lg:px-[3.125rem]">
            <form className="space-y-5">
              <div className="flex flex-col gap-2">
                <label
                  className="font-inter text-base  font-medium leading-[1.3rem] text-white"
                  htmlFor="Username"
                >
                  Email Address<span className="text-[#F75F00]">*</span>
                </label>
                <input
                  className="rounded border-[0.5px] border-[#fff] bg-transparent px-4 py-2.5 font-poppins text-lg font-normal leading-[1.463rem] text-[#fff]"
                  type="email"
                  placeholder="Email Address"
                  onChange={(e) =>
                    setLoginInfo({ ...LoginInfo, email: e.target.value })
                  }
                  defaultValue={userInformation?.email}
                  required
                />
              </div>
              <div className="flex flex-col gap-2 ">
                <label className="inter-500 font-inter text-base leading-[1.3rem] text-white">
                  Password<span className="text-[#F75F00]">*</span>
                </label>
                <input
                  className="poppins-400 rounded border-[0.031rem] border-[#fff] bg-transparent px-4 py-2.5 text-lg leading-[1.463rem] text-[#fff]"
                  type="password"
                  placeholder="Enter Your Password"
                  onChange={(e) =>
                    setLoginInfo({ ...LoginInfo, password: e.target.value })
                  }
                  defaultValue={userInformation?.password}
                  required
                />
              </div>
              <div className="flex flex-wrap items-center justify-between">
                <div className="flex flex-wrap items-center  ">
                  <input
                    className="checkbox h-[20px] w-[20px] cursor-pointer rounded-md border border-white"
                    type="checkbox"
                    id="rememberMe"
                    name="checkedBtn"
                    checked={isChecked}
                    onClick={handleCheckboxChange}
                  />
                  <label
                    className="cursor-pointer pl-2 font-poppins text-base font-medium leading-[1.3rem] tracking-[0.32px] text-[#fff]"
                    htmlFor="rememberMe"
                  >
                    Remember Me
                  </label>
                </div>
                <div>
                  <Link
                    className="font-poppins text-base leading-[1.3rem] tracking-[0.32px] text-white underline hover:text-blue-500 hover:underline hover:underline-offset-2"
                    to="/crm-recover-account"
                  >
                    Forget Password?
                  </Link>
                </div>
              </div>
              <div>
                {!LoginInfo.email || !LoginInfo.password ? (
                  <button
                    disabled
                    className="w-full bg-white p-2.5 font-inter text-base font-semibold leading-[1.3rem] tracking-[0.32px] text-black"
                  >
                    Login Disabled
                  </button>
                ) : (
                  <button
                    type="submit"
                    onClick={(e) => LoginFun(e)}
                    className="w-full bg-white p-2.5 font-inter text-base font-semibold leading-[1.3rem] tracking-[0.32px] text-black"
                  >
                    {isLoading === true ? "Loading..." : "LOGIN"}
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Login;
