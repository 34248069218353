import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../Styles/Auth/recoverAccount.css";
import axios from "axios";
import toast from "react-hot-toast";
import { ENDPOINT } from "../../App/config/endpoint";
import HotToaster from "../../Component/Shared/HotToaster";
import { AuthContext } from "../../Provider/AuthProvider";
const OnBoarding = () => {
  const { setRecoverToken, recoverToken } = useContext(AuthContext);
  const [recoverInfo, setRecoverInfo] = useState({
    email: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const handleVerifyEmail = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const verifyCode = e.target.verifyCode?.value;
    if (!verifyCode) {
      setIsLoading(false);
      return toast.error("Please enter verification code!");
    }
    const apiUrl = `${process.env.REACT_APP_MAIN_URL}${ENDPOINT.agent.agentRecover}`;
    const headers = {
      "Content-Type": "application/json",
    };
    const postData = {
      verifyCode: verifyCode,
    };

  // console.log(postData);
    axios
      .post(apiUrl, postData, { headers })
      .then((res) => {
      // console.log("recover res", res);
        setRecoverInfo({ email: "" });
        setRecoverToken(res.data.token);
        setIsLoading(false);
        navigate("/crm-create-new-password");
      })
      .catch((err) => {
        toast.error("Something wen't wrong!");
      // console.log(err);

        setIsLoading(false);
      });
  };

  // console.log(recoverInfo);

  useEffect(() => {
    if (!recoverToken) {
      // console.log("consol");
      navigate("/crm-login");
    }
  }, [recoverToken]);
  return (
    <div className="flex min-h-screen w-full items-center justify-center bg-white py-5">
      <div className="mx-auto min-w-[250px] rounded-b-lg rounded-t-xl bg-black shadow-loginShadow md:min-w-[400px] lg:min-w-[600px]">
        <div className="recover_account_banner rounded-lg pb-[5.938rem] pt-[5.820rem]">
          <div className="px-3 text-center">
            <h2 className=" font-poppins text-[2rem] font-semibold leading-[2.6rem] text-black">
              Verify Your Email
            </h2>
            <p className="text-secondBlack font-poppins text-[14px] font-medium">
              Check your email for verification code
            </p>
          </div>
        </div>
        <form action="" onSubmit={handleVerifyEmail}>
          <div className="px-[1rem] pb-[5rem] pt-[4.063rem] md:px-[2.125rem] lg:px-[3.125rem]">
            <div className="space-y-5">
              <div className="flex flex-col gap-2">
                <label
                  className="inter-500 font-inter text-base leading-[1.3rem] text-white"
                  htmlFor="verificationCode"
                >
                  Verification Code<span className="text-[#D3AC46]">*</span>
                </label>
                <input
                  className="rounded border-[0.5px] border-[#fff] bg-transparent px-4 py-2.5 font-poppins text-lg font-normal leading-[1.463rem] text-[#fff]"
                  type="text"
                  name="verificationCode"
                  placeholder="Verification Code"
                  value={recoverInfo.email || ""}
                  onChange={(e) =>
                    setRecoverInfo({
                      ...recoverInfo,
                      email: e.target.value,
                    })
                  }
                />
              </div>
              <div className="flex flex-wrap items-center justify-between">
                <div className="flex flex-wrap items-center">
                  <Link
                    to="/crm-login"
                    className="cursor-pointer font-poppins text-base font-medium leading-[1.3rem] tracking-[0.32px] text-[#fff] underline hover:text-blue-500 hover:underline hover:underline-offset-2"
                  >
                    Login Here
                  </Link>
                </div>
              </div>
              <div>
                <button className="w-full bg-white p-2.5 font-inter text-base font-semibold leading-[1.3rem] tracking-[0.32px] text-black">
                  {isLoading ? "Verifying..." : "Verify"}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default OnBoarding;
