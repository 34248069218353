import React, { useState } from "react";
import ImageUploader from "../Shared/ImageUploader";
import { useContext } from "react";
import { AuthContext } from "../../Provider/AuthProvider";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

const Rollover = ({ setModalOpen, data, endpoint }) => {
  // console.log('rollover: ', data);
  const [voucher, setVoucher] = useState({});
  const [number, setNumber] = useState(0);
  const { token } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  const decrement = () => {
    if (number > 0) {
      setNumber(number - 1);
    }
  };

  const increment = () => {
    setNumber(number + 1);
  };

  const handleAmountChange = (event) => {
    const newValue = event.target.value; // Get the input value as a string

    if (newValue === "" || !isNaN(newValue)) {
      setNumber(newValue === "" ? null : parseFloat(newValue)); // Use parseFloat to handle numeric values
    }
  };

  const handleUpdateRollover = (e) => {
    setIsLoading(true);
    e.preventDefault();
    const form = e.target;
    const apiUrl = endpoint;
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };
    if (!voucher) {
      setIsLoading(false);
      return toast.error("Please upload a voucher!");
    }
    if (number < 1) {
      setIsLoading(false);
      return toast.error("Please provide a valid amount!");
    }
    if (!form.utr.value) {
      setIsLoading(false);
      return toast.error("Please provide a utr link!");
    }

    const formData = new FormData();
    // TODO::
    formData.append("voucher_img", voucher);
    formData.append("loan_id", data?.id);
    formData.append("user_id", data?.user_id);
    formData.append("utr", form?.utr?.value);
    formData.append("amount", number);

    // console.log(apiUrl, formData);
    axios
      .post(apiUrl, formData, { headers })
      .then((res) => {
        // console.log(res);
        if (res.data.status) {
          toast.success(res.data.message);
          setModalOpen(false);
          setIsLoading(false);
          setVoucher({});
        }
      })
      .catch((err) => {
        // console.log(err);
        setIsLoading(false);
      });
  };
  return (
    <div className="pt-2">
      <form onSubmit={handleUpdateRollover}>
        <div className="my-[12px]">
          <h1 className="mb-1 text-base font-medium text-secondBlack">
            Amount<span className="text-[#F0242F]">*</span>
          </h1>
          <div className="number-input flex items-center">
            <button
              type="button"
              className="h-9 w-10 rounded-l-[5px] bg-gray-200 text-xl font-bold text-gray-700"
              onClick={decrement}
            >
              -
            </button>
            <input
              type="text" // Use type "text" for numeric input
              className="h-9 w-full cursor-text text-center text-gray-800"
              value={number}
              onChange={handleAmountChange} // Handle input changes
            />
            <button
              type="button"
              className="h-9 w-10 rounded-r-[5px] bg-gray-200 text-xl font-bold text-gray-700"
              onClick={increment}
            >
              +
            </button>
          </div>
        </div>

        <div className="my-[12px]">
          <h1 className="mb-1 text-base font-medium text-secondBlack">
            UTR<span className="text-[#F0242F]">*</span>
          </h1>
          <input
            type="text"
            className="h-9 w-full cursor-text rounded-[5px] px-3 text-left font-poppins text-gray-800"
            name="utr"
            placeholder="Enter Url Link"
          />
        </div>

        <div className="my-[12px]">
          <h1 className="mb-1 text-base font-medium text-secondBlack">
            Upload Voucher<span className="text-[#F0242F]">*</span>
          </h1>
          <div className="w-full max-w-[300px]">
            <ImageUploader
              image={voucher}
              setState={setVoucher}
              className=" w-full"
            />
          </div>
        </div>
        <div className="my-6 flex gap-3">
          <button
            type="button"
            onClick={() => setModalOpen(false)}
            className="rounded-[4px] bg-[#EF4E57] px-8 py-[6px] text-base font-medium text-white"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="rounded-[4px] bg-[#0EAB8B] px-8 py-[6px] text-base font-medium text-white"
          >
            {isLoading ? "Updating..." : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Rollover;
